.dodfminer-content {
    display: flex;
    text-align: center;
    place-items: center;
}

.miner-logo {
    width: 50%;
}

.frame-img {
    width: 100%;
}
.miner-btn a{
    margin: 5px;
}

.miner-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
}

.miner-info p {
    margin-top: 4em;
    opacity: .8;
}

@media screen and (max-width: 860px) {
  .tools {
    padding: 20px !important;
  }
}
.carousel {
    display: flex;
    width: 100%;
  }

  .tools {
    padding: 5em;
  }

  .tools ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px;
  }
  .tool-item {
    display: none;
    max-width: 50%;
    padding: 40px;
  }

  .tool-item.show {
    display: grid;
    place-items: center;
  }

  .tool-item-btn {
    background-color: var(--theme-color);
    color: white;
    border: none;
    border-radius: 40px;
    padding: 10px;
  }

  .tool-left {
    display: flex;
    flex-direction: column;
    place-items: center;
    text-align: center;
    width: 100%;
  }

  .tool-date {
    margin-top: 40px;
  }

  .tools-btn {
    display: flex;
    gap: 15px;
  }

  .tools-action {
    background: none;
    color: white;
    border: none;
    border-radius: 10px;
  }

  .tools-icon {
    padding: 10px;
    border-radius: 20px;
  }

  .tools-action:hover .tools-icon {
    color: #333;
  }

  .tools-action:hover {
    background: #ffffff99;
  }

  .index-marker {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .marker-dot {
    width: 10px;
    height: 10px;
    border: 2px solid white;
    border-radius: 10px;
  }

  .marker-dot.marked {
    background: white;
  }

  .description {
    color: white;
    opacity: 0.8;
  }
