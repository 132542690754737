.sidebar {
    position: fixed;
    display: none;
    flex-direction: column;
    background: var(--theme-color);
    width: 300px;
    height: 100vh;
    right: -300px;
    top: 0;
    gap: 15px;
    padding-top: 2em;
}

.sidebar.active {
    right: 0;
}

.sidebar-item {
    background: none;
    border: none;
    padding: 5px 0;
    padding-right: 30px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    color: white;
    font-size: 14px;
}

.sidebar-item.link {
    background: #ffffffaa !important;
    color: var(--theme-color);
}

.sidebar-item:hover {
    background: #ffffff77;
}

.sidebar-flags {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 30px;
    justify-content: flex-start;
}

.sidebar-flag {
    background: none;
    border: none;
    cursor: pointer;
}

.bars {
    background: none;
    border: none;
    position: relative;
    right: calc(var(--container-width) / 2);
    top: 0;
    /* margin-right: 15px; */
}

.bars.active {
    right: 300px;
}

@media (max-width: 992px) {
    .bars {
        display: flex !important;
        position: relative;
        right: calc(var(--container-width) / 2);
        top: 0;
    }

    .sidebar {
        display: flex;
    }
}