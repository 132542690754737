.flex-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
    gap: 24px;
}

.flex-image {
    text-align: center;
    background: white;
    display: flex;
    place-items: flex-start;
    justify-content: center;
    width: 120px;
    height: 250px;
    border-radius: 15px;
    padding: 15px;
}

.flex-image.active {
    position: fixed;
    top: calc(50% - 140px);
    left: var(--container-width) / 2;
    z-index: 900;
    justify-content: start;
    width: var(--container-width);
    height: auto;
    box-shadow: 0px 5px 100px 10px rgb(0 0 0 / 80%);
}

.flex-image.active:hover {
    box-shadow: 0px 5px 100px 10px rgb(0 0 0 / 80%);
}

.flex-image:hover:not(.active) {
    box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.flex-image-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    place-items: center;
    padding: 10px;
    gap: 15px;
}

.flex-image-info h5 {
    margin: 0;
}

.flex-image.active .flex-image-info img {
    width: 200px;
    height: 200px;
}

.flex-image-info img {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 20%);
    object-fit: cover;
}

.flex-image.active .flex-image-description {
    display: flex;
}

.flex-image-description {
    display: none;
    flex-direction: column;
    text-align: left;
    margin-left: 50px;
    padding: 15px;
    justify-content: space-between;
}

.flex-image-description h3, .flex-image-description a {
    margin: 8px 0;
}

.close-image {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #666;
    padding: 5px 8px;
    border-radius: 100%;
}

.media {
    display: flex;
    gap: 15px;
    margin: 16px 0; 
}

.members-icon {
    width: 20px !important;
    height: auto;
}

.close-image:hover {
    color: white;
    background: #666;
}

.orcid-btn,
.lattes-btn {
    border-radius: 0 !important;
    display: flex;
    place-items: center;
    font-weight: bold;
    height: 28px;
}

.orcid-btn {
    background: green;
}