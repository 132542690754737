.modal {
    background: #666666b0;
    position: fixed;
    display: flex;
    z-index: 999;
    justify-content: center;
    place-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modal-content {
    display: grid;
    grid-template-rows: 25px calc(100% - 40px);
    gap: 10px;
    min-width: 300px;
    min-height: 200px;
    max-height: 85%;
    background: white;
    border-radius: 5px;
    padding: 15px;
    margin-top: 2.5%;
}

.modal-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 25px;
}

.modal-button button {
    background: var(--theme-color);
    color: white;
    border: 1px solid var(--theme-color);
    border-radius: 90px;
    width: 25px;
    height: 25px;
    display: flex;
    place-items: center;
    justify-content: center;
}

.modal-button button:hover {
    cursor: pointer;
    background: white;
    color: var(--theme-color);
}

.modal-body {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    /* justify-content: center; */
    /* place-items: center; */
}