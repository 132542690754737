section {
    padding: 2em;
}

section:not(.frontpage, .partners):nth-child(2n) {
    background: #2c2c2c;
    color: white;    
}

section:not(.frontpage, .partners):nth-child(2n - 1) {
    background: #f6f6f6;
}