.navbar {
    position: fixed;
    min-height: 53px;
    margin-bottom: 20px;
    padding: 0.625rem 0;
    width: 100%;
    background: var(--theme-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    transition: .3s;
    z-index: 1000;
}

.navbar.no-bg {
    background: transparent !important;
    box-shadow: none;
    margin-top: 15px;
}

.navbar-img {
    height: 3.75em;
    max-width: 100px;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
}

.navbar-menu {
    display: flex;
    gap: 15px;
    place-items: center;
}

.logo {
    background: none;
    border: none;
    cursor: pointer;
}

.navbar-item {
    display: flex;
    gap: 5px;
    place-items: center;
    padding: 10px;
    color: white;
    text-decoration: none;
    transition: .3s;
    background: none;
    border: none;
    font-size: 14px;
}

.navbar-item.link {
    background: #ffffffdd;
    color: var(--theme-color);
}

.github-logo {
    filter: invert(1);
}

.navbar-item:hover {
    background: #ffffff77
}

.navbar-flags {
    display: flex;
    gap: 5px;
    place-items: center;
}


.navbar-flag {
    background: transparent !important;
    border: none;
    cursor: pointer;
}

.no-bg .navbar-flags {
    opacity: .7 !important;
}

@media (max-width: 992px) {
    .navbar-item:not(.bars), .navbar-flags {
        display: none !important;
    }
}
