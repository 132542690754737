:root {
  --theme-color: #0F4C81;
  background: #f6f6f6;
  transition: .3s;
}

section {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.btn {
  background-color: #092472;
  color: white;
  border: none;
  border-radius: 40px;
  padding: 10px;
}

.btn:hover {
  background: #fa7a50;
  cursor: pointer;
}

.centered {
  text-align: center;
}

.hide {
  display: none;
}

.card {
  box-shadow: 0px 5px 25px 0px rgb(0 0 0 / 20%);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 1.825em
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.3em;
}

a {
  color: #f96332;
  text-decoration: none;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
    --container-width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
    --container-width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    --container-width: 1170px;
  }
}