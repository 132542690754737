.frontpage {
    background: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(2, 66, 204, 0.514));
    height: 100vh;
}


.frontpage-content {
    padding-top: 100px;
    display: grid;
    place-items: center;
    height: 80%;
    justify-content: space-around;
}

.frontpage-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 70px
}

.frontpage-logo img {
    max-width: 500px;
}

.frontpage-logo h4 {
    color: white;
    font-size: 1.5em;
}

.frontpage-brands {
    display: flex;
    place-items: center;
}

.unbLogo { 
    max-width: 300px;
}

.faplogo {
    max-height: 300px;
}

.iconDown {
    color: #fff;
    animation: blink 2s infinite linear;
}

@keyframes blink {
    0% {opacity: .5; margin-top: -10px; margin-bottom: 0px;}
    50% {opacity: 1; margin-top: 0px; margin-bottom: -10px;}
    100% {opacity: .5; margin-top: -10px; margin-bottom: 0px;}
}

.blankspace {
    height: 70px;
}

.alert {
    display: flex;
    flex-direction: column;
    place-items: center;
    background: white;
    padding: 15px;
    border-radius: 15px;
}

.alert-header {
    display: flex;
    place-items: center;
    gap: 15px;
    margin-top: 15px;
}

.alert-body {
    margin: 15px 0;
    text-align: center;
}

.alert-body a:hover {
    cursor: pointer;
}

.alert-header h4,
.alert-body h5 {
    margin: 0;
    font-weight: bold;
}

.alert-body span {
    font-weight: bold;
    color: var(--theme-color);
}

.alert-body span:hover {
    text-decoration: underline;
    cursor: pointer;
}