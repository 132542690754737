.contact {
    background-color: #2c2c2c !important;
    color: white;
    text-align: center;
}

.contact-content {
    height: calc(100vh - 150px);
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    place-items: center;
}

.contact-links {
    display: flex;
    gap: 15px;
}

.contact-links a {
    background-color: #f6f6f6;
    color: #2c2c2c;
    padding: 10px;
    display: flex;
    gap: 5px;
    place-items: center;
    font-size: 14px;
}

.contact-us {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.description {
    padding: 0 10%;
}

.contact p {
    opacity: .8;
}
.contact img {
    background: white;
    padding: 20px;
    border-radius: 100%;
}