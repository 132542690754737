.about-video {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    max-height: 600px;
    width: 100%;
}

.dodf-image {
    margin: 10px;
}
